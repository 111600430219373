import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_page" }
const _hoisted_2 = { class: "_page-tab" }
const _hoisted_3 = { class: "_page-body" }

import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ApiUsage',
  setup(__props) {

const route = useRoute();

const checkRoute = (path: string) =>
  route.path === `/dashboard/api_usage${path}` ? "_page-tab_active" : "";

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("section", { class: "_page-header" }, [
      _createElementVNode("h1", null, "API usage overview")
    ], -1)),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("nav", null, [
        _createVNode(_component_RouterLink, {
          class: _normalizeClass(checkRoute('/monthly_usage')),
          to: "/dashboard/api_usage/monthly_usage"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Monthly usage")
          ])),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_RouterLink, {
          class: _normalizeClass(checkRoute('/rate_limit')),
          to: "/dashboard/api_usage/rate_limit"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Rate limit ")
          ])),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_RouterView)
    ])
  ]))
}
}

})