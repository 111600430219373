import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "_content" }
const _hoisted_2 = { class: "_header" }
const _hoisted_3 = {
  class: "_input",
  name: "product",
  id: "product"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "_product" }
const _hoisted_6 = { class: "_component" }
const _hoisted_7 = ["onClick"]

import { Component, getComponents } from "@/api/api_usage";
import Icon from "@/components/Icon.vue";
import InputBox from "@/components/InputBox.vue";
import TableSection from "@/components/TableSection.vue";
import { onBeforeMount, ref, watch } from "vue";
import { PRODUCTS } from "@/constants";
import EndpointListModal, {
  Row,
} from "@/components/api_usage/EndpointListModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RateLimitTab',
  setup(__props) {

const isEndpointListModalOpen = ref<boolean>(false);

const selectedComponents = ref<Row[]>([]);
const components = ref<Component[]>([]);
const totalComponents = ref<number>(0);
const size = ref<number>(10);
const page = ref<number>(1);

const fetchData = async () => {
  const { data, totalData } = await getComponents(size.value, page.value);
  components.value = data;
  totalComponents.value = totalData;
};

const test = (e) => {
  console.log(e);
};

const onSelectComponent = (component: Component) => {
  isEndpointListModalOpen.value = true;
  selectedComponents.value = component.endpoints;
};

onBeforeMount(async () => {
  fetchData();
});

watch(size, () => {
  fetchData();
});

watch(page, () => {
  fetchData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("select", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("option", {
          value: "",
          selected: "",
          disabled: ""
        }, "All products", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PRODUCTS), (product) => {
          return (_openBlock(), _createElementBlock("option", {
            key: product,
            value: product
          }, _toDisplayString(product[0].toUpperCase() + product.slice(1)), 9, _hoisted_4))
        }), 128))
      ]),
      _createVNode(InputBox, { "left-icon": "magnifying-glass" })
    ]),
    _createVNode(TableSection, {
      "total-data": totalComponents.value,
      onChangeSize: _cache[0] || (_cache[0] = (newSize) => (size.value = newSize)),
      onNext: _cache[1] || (_cache[1] = (newPage) => (page.value = newPage)),
      onPrev: _cache[2] || (_cache[2] = (newPage) => (page.value = newPage))
    }, {
      error: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("div", { class: "_error" }, [
          _createElementVNode("img", {
            src: "https://cdn.mekari.design/illustration/blank-slate/NoData_PB_M_01.png",
            alt: "no-data"
          }),
          _createElementVNode("h1", null, "No rate limit details yet"),
          _createElementVNode("p", null, [
            _createTextVNode(" Once you create and app from "),
            _createElementVNode("strong", null, "Application"),
            _createTextVNode(" menu, your rate limit details will appear here. ")
          ])
        ], -1)
      ])),
      header: _withCtx(() => _cache[6] || (_cache[6] = [
        _createElementVNode("th", null, "Product", -1),
        _createElementVNode("th", null, "Component name", -1),
        _createElementVNode("th", null, "Rate limit", -1)
      ])),
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(components.value, (row, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("div", null, [
                _createVNode(Icon, {
                  product: row.product,
                  size: "1.5rem"
                }, null, 8, ["product"])
              ]),
              _createElementVNode("p", null, " Mekari " + _toDisplayString(row.product[0].toUpperCase() + row.product.slice(1)), 1)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(row.name), 1),
                _createElementVNode("a", {
                  onClick: ($event: any) => (onSelectComponent(row)),
                  class: _normalizeClass(row.endpoints.length > 1 ? '_link' : '_non-link')
                }, _toDisplayString(row.endpoints.length > 1
                    ? "View endpoint URL"
                    : row.endpoints[0].url), 11, _hoisted_7)
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("p", null, _toDisplayString(row.rateLimit) + "/minute", 1)
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["total-data"]),
    _createVNode(EndpointListModal, {
      "is-modal-open": isEndpointListModalOpen.value,
      data: selectedComponents.value,
      "total-data": selectedComponents.value.length,
      onSearch: test,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (isEndpointListModalOpen.value = false))
    }, null, 8, ["is-modal-open", "data", "total-data"])
  ]))
}
}

})